.cell-span {
  background-color: #ffffff;
  /*border-bottom: 1px solid #dddddd;*/
}

.ag-theme-alpine {
  --ag-header-height: 28px;
  --ag-header-foreground-color: #000000DE;
  --ag-header-background-color: #ffffff;

  --ag-foreground-color: #555555;

  --ag-font-size: 12px;
  --ag-font-weight: 500;

  --ag-borders: none;

  --ag-header-border-bottom-style: solid;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: #dddddd;
}

.ag-theme-alpine .ag-header {
  font-family: inherit;
}

.ag-header-cell {
  border-bottom: 1px solid #dddddd;
}

.ag-header-subTitle {
  color: #00000061;
}


/* WORKFLOW PAGE: */
.future-step {
  background-color: #ffffff0a;
}

.current-step {
  background-color: #3FCD40;
}

.complete-step {
  background-color: #c9c9c990;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

/* Specific styles for selected cells */
.ag-cell-range-selected.future-step {
  background-color: rgba(71, 71, 255, 0.2) !important;
}

.ag-cell-range-selected.current-step {
  background-color: rgba(155, 39, 176, 0.168) !important;
}

.ag-cell-range-selected.complete-step {
  background-color: rgba(97, 97, 97, 0.449) !important;
}

.workflow-context-menu .ag-menu-option-text {
  color: black;
  font-weight: bold;
  font-size: 15px;
}