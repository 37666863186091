body {
  margin: 0;
  padding: 0;
  background-color: rgb(255, 255, 255);
  font-family: 'Roboto', sans-serif;
}

  .whiteBackground {
    background-color: 'white';
    height: 100%;
    overflow: auto;
  }

  .form-container {
    max-width: 600px;
    width: 100%;
  }

  .ag-cell.red{
    background-color: #ec106544;
  }
   
  .ag-cell.green{
    background-color: #19a36344;
  }

  .ag-cell.white{
    background-color: none;
  }

 /* Min height for quill editor */
  .custom-quill-editor .ql-editor {
    min-height: 100px;
}
   