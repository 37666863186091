.tree {
  font-family: Arial, sans-serif;
  user-select: none;
}

.tree ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.tree-node {
  cursor: pointer;
  padding: 5px;
  position: relative; /* Make it relative for positioning pseudo-elements */
}

.tree-node:hover {
  background-color: #f0f0f0;
}

.tree-node.selected {
  background-color: #d0e0ff;
}

.tree-node-content {
  display: flex;
  align-items: center;
}

.tree-node-label {
  margin-left: 8px;
}

.tree-node-children {
  padding-left: 10px;
}
