
.tree-node.hovered-child {
  background-color: #d0f0ff; /* Change background color when dropping as a child */
}

.tree-node.hovered-child::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000; /* Color of the arrow */
}

.tree-node.hovered-sibling {
  position: relative;
}

.tree-node.hovered-sibling::before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 10px; /* Adjust this value to reduce the border width from the left */
  right: 20px; /* Adjust this value to reduce the border width from the right */
  height: 2px;
  background-color: #000; /* Border color */
  z-index: 1;
}

.tree-node.hovered-sibling::after {
  content: '';
  position: absolute;
  bottom: -3px; /* Adjust this value to align the arrow with the bottom border */
  left: 0; /* Position the arrow to the left of the node */
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000; /* Color of the arrow */
}

.tree-node:active {
  cursor: grabbing; /* Provide visual feedback when dragging */
}

.tree-node.dragging {
  background-color: #ffa500 !important; /* Change background color */
  opacity: 0.8;
  border: 2px dashed #000; /* Dashed border */
}

.drag-preview {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ffa500; /* Match the dragging class background color */
  opacity: 0.8; /* Slightly transparent */
  border: 2px dashed #000; /* Dashed border */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Box shadow */
  font-size: 14px; /* Font size */
  color: #fff; /* Text color */
  z-index: 99999;
}